import _ from 'lodash';

let unsubscribeFromWorkbook = _.noop;
let unsubscribeFromWorkstepChannel = _.noop;
let unsubscribeFromReportUpdatesChannel = _.noop;

export function cleanupOnUnload() {
  unsubscribeFromWorkbook();
  unsubscribeFromWorkstepChannel();
  unsubscribeFromReportUpdatesChannel();
}

export function setUnsubscribeFromWorkbook(functionDef: () => void) {
  unsubscribeFromWorkbook = functionDef;
}

export function setUnsubscribeFromWorkstepChannel(functionDef: () => void) {
  unsubscribeFromWorkstepChannel = functionDef;
}

export function setUnsubscribeFromReportUpdatesChannel(functionDef: () => void) {
  unsubscribeFromWorkstepChannel = functionDef;
}
