import React, { useCallback } from 'react';
import { TreemapPropsOnly, TreemapPropsOnlyProps } from '@/treemap/TreemapPropsOnly.molecule';
import { useDidMount } from 'rooks';
import { sqReportStore } from '@/core/core.stores';
import { saveAssetSelection } from '@/reportEditor/report.actions';
import { doTrack } from '@/track/track.service';
import { isEditWorkbookMode, isPresentationWorkbookMode, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { isDashboard } from '@/annotation/contentSelector.utilities';
import { buildRelativeContentUrl } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';

export interface TreemapContentProps extends TreemapPropsOnlyProps {
  contentId: string;
  onContentLoad: () => void;
}

/**
 * In order to match some of the functionality in the actual Treemap visualization, we need to provide certain
 * callbacks to the underlying components to provide interactivity.
 */
export const TreemapContent: React.FunctionComponent<TreemapContentProps> = (props) => {
  const { onContentLoad, contentId } = props;
  useDidMount(() => onContentLoad());

  const onClickNode = useCallback(
    (asset: { id: string } | undefined, isLeafNode: boolean) => {
      if (isLeafNode && isEditWorkbookMode()) {
        const content = sqReportStore.getContentById(contentId);
        if (content.assetSelectionId && asset) {
          const assetSelection = sqReportStore.getAssetSelectionById(content.assetSelectionId);
          doTrack('Topic', 'Asset Selection', 'Changed');
          saveAssetSelection({ ...assetSelection, asset });
        }
      } else if (isViewOnlyWorkbookMode() || isPresentationWorkbookMode()) {
        window.open(buildRelativeContentUrl(contentId), '_self');
      }
    },
    [contentId],
  );

  return <TreemapPropsOnly {...props} onClickNode={onClickNode} />;
};
