import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { InputGroup } from 'react-bootstrap';
import _ from 'lodash';
import { Button } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { FormControlMemo } from '@/formbuilder/FormControlFormComponent.atom';

export interface InputWithLinkedButtonsProps extends ValidatingFormComponent<any> {
  component: 'InputWithLinkedButtonsFormComponent';
  customErrorText?: string;
  placeholder?: string;
  removeAction: () => void;
  buttonAction: () => void;
  hideTrashButton?: boolean;
  includeAddButton?: boolean;
  addButtonLabel?: string;
  labelIconTooltip?: string;
  maxLength?: number;
}

export const InputWithLinkedButtonsFormComponent: React.FunctionComponent<InputWithLinkedButtonsProps> = (props) => {
  const {
    name,
    testId = 'ValueInput',
    onChange,
    customErrorText,
    placeholder = '',
    removeAction,
    buttonAction,
    hideTrashButton,
    includeAddButton,
    addButtonLabel = '',
    labelIconTooltip = '',
    maxLength,
    required,
  } = props;

  const { t } = useTranslation();
  const defaultValidation = (value: string) =>
    (required && _.isEmpty(_.trim(value))) || (maxLength && value.length > maxLength);
  const appliedValidation = getValidationFunction(defaultValidation, props.extendValidation, props.validation);
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  return (
    <>
      <FormFieldWrapper
        {...getFormFieldWrapperProps(props, ['id'])}
        wrapperClassNames="flexFill"
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
              placeholder: t(placeholder),
              value: input.value,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                input.onChange(e.target.value);
                onChange?.(e.target.value);
              },
            });
            return (
              <InputGroup>
                <FormControlMemo
                  {..._.omit(properProps, [
                    'fromFormBuilder',
                    'onTooltipClick',
                    'testId',
                    'component',
                    'elementType',
                    'extraClassNames',
                    'includeIf',
                    'customErrorParams',
                    'customErrorText',
                    'extendValidation',
                    'validation',
                    'showError',
                    'fixedWidth',
                    'fieldNames',
                    'displayNumber',
                    'label',
                    'number',
                    'minLength',
                    'skipMemo',
                    'onIconClick',
                    'wrapperClassNames',
                  ])}
                  type="text"
                  placeholder={t(placeholder)}
                  className={properProps.extraClassNames}
                  maxLength={maxLength}
                  data-testid={`${testId}-input`}
                  required={properProps.showError && properProps.required}
                />
                <InputGroup.Append>
                  {includeAddButton && (
                    <Button
                      label={t(addButtonLabel)}
                      tooltip={t(labelIconTooltip)}
                      onClick={buttonAction}
                      variant="outline"
                    />
                  )}
                  {!hideTrashButton && <Button icon="fa-trash" onClick={removeAction} iconStyle="theme" />}
                </InputGroup.Append>
              </InputGroup>
            );
          }}
        </Field>
      </FormFieldWrapper>
    </>
  );
};
